import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import themeConfig from './modules/themeConfig'
import verticalSidebar from './modules/verticalSidebar'
import clients from './modules/admnistration/clients'
import invoice from './modules/invoices/invoice'
import authentication from './modules/sessions/authentication'
import statistics from './modules/dashboard/statistics'
import users from './modules/admnistration/users'
import credentialsTef from './modules/admnistration/credentialsTef'
import folios from "@/store/modules/invoices/folios";
import certificates from "@/store/modules/invoices/certificates";
import numerations from "./modules/admnistration/numerations";

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    themeConfig,
    invoice,
    verticalSidebar,
    clients,
    numerations,
    authentication,
    statistics,
    users,
    credentialsTef,
    folios,
    certificates,
  },
})
